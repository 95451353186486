import React, { useEffect, useState } from 'react';
import { BlogsType } from '../types';
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

export default function Blogs() {

  const [Data, setData] = useState<{ blogs: BlogsType[] } | null>();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState<number>(0);
  const limit = 12;

  useEffect(() => {

    fetch('/blogs.json').then(async (response) => {
      const data: { blogs: BlogsType[] } = await response.json();
      const start = (page - 1) * limit;
      const end = page * limit;
      setTotalPage(Math.ceil(data?.blogs?.length / limit));
      setData({ blogs: data?.blogs.slice(start, end) });

    }).catch((err: any) => console.log(err));

    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);


  return (
    <div className='mb-5'>
      <div className='row'>
        {Data?.blogs && Data?.blogs.length === 0 ?
          <div className='d-flex justify-content-center flex-column align-items-center' style={{ minHeight: 'inherit' }}>
            <span className='h4'>No Data Found</span>
          </div>
          : null}
        {
          Data?.blogs && Data?.blogs.map((item, i) => (
            <div className='col-md-6 col-lg-4 col-xxl-3 col-12 my-3' key={i}>
              <div className='shadow' onClick={() => navigate(`/post/${item?.slug}`)}>
                <div className='z-1'>
                  <img src={item?.image} className='w-100 cursor-pointer' alt='banner.png' height={300} width={300} style={{ height: 300, objectFit: 'cover', objectPosition: 'top center' }} />
                </div>
                <div className='px-3 z-3' >
                  <div className='d-flex justify-content-between flex-wrap align-items-center py-2'>
                    <h2 className='h4 cursor-pointer'>{item?.title}</h2>
                    <p className='text-gray'>{item?.short_description.slice(0, 100) +
                      (item?.short_description?.length > 100 ? "..." : "")}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        {totalPage ? (
          <ReactPaginate
            previousLabel={<FaArrowLeft />}
            nextLabel={<FaArrowRight />}
            breakLabel={"..."}
            pageCount={totalPage}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            forcePage={page - 1}
            onPageChange={(pageClicked) => {
              setPage(pageClicked.selected + 1);
            }}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"pagenation-items"}
            pageLinkClassName={"pagenation-link"}
            previousClassName={"pagenation-items"}
            previousLinkClassName={"pagenation-items mx-2"}
            nextClassName={"pagenation-items"}
            nextLinkClassName={"pagenation-items mx-2"}
            breakClassName={"pagenation-items"}
            breakLinkClassName={"pagenation-link"}
            activeClassName={"active"}
          />
        ) : null}
      </div>
    </div>
  );
}
