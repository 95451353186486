import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import CustomNavbar from './components/Navbar';
import { jsonType } from './types';
import Footer from './components/Footer';
import SideBar from './components/SideBar';
import { useGlobalStore } from './store';

export default function Layout() {

    const [Data, setData] = useState<jsonType>();
    const [setIsMobile, showSideBar] = useGlobalStore((state: any) => [state.setIsMobile, state.showSideBar]);
    useEffect(() => {
        fetch('/db.json').then(async (response) => setData(await response.json()))
            .catch((err: any) => console.log(err));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const update = () => {
            if (window.innerWidth >= 780) {
                setIsMobile(false);
            } else {
                setIsMobile(true);
            }
        }
        window.addEventListener('resize', update);
        return () => window.removeEventListener('resize', update);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <CustomNavbar name={Data?.name!} number={Data?.number!} navItem={Data?.category?.map(item=>({label:item?.category_name,slug:item?.slug}))!} />
            <div style={{ height: 85 }} />
            {showSideBar ? <SideBar navItem={Data?.category?.map(item=>({label:item?.category_name,slug:item?.slug}))!}/> : <Container style={{ minHeight: "calc(100vh - 304px)" }}>
                <Outlet />
            </Container>}

            <Footer name={Data?.name!} number={Data?.number!} social_link={Data?.social_link!} />
        </>
    );
}
