import React, { useEffect, useState } from 'react';
import { jsonType, productType } from '../types';
import { useNavigate, useParams } from 'react-router-dom';
import { IoLogoWhatsapp } from 'react-icons/io';
import ReactWhatsapp from 'react-whatsapp';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import Skeleton from 'react-loading-skeleton';

export default function ProductView() {

  const [isLoading, setIsLoading] = useState(true);
  const [Data, setData] = useState<jsonType>();
  const [product, setProduct] = useState<productType>();
  const [relatesProduct, setReleatedProduct] = useState<productType[]>([]);
  const [showImage, setShowImage] = useState<string>("");
  const navigate = useNavigate();
  const { slug } = useParams();

  useEffect(() => {
    fetch('/db.json').then(async (response) => {
      const data: jsonType = await response.json();
      setData(data)
      const category = data?.category.find(i => i?.product?.find(p => p?.slug === slug))
      setProduct(category?.product.find(p => p?.slug === slug));
      setShowImage(category?.product.find(p => p?.slug === slug)?.img[0]!);
      setReleatedProduct(category?.product.filter(i => i.slug !== slug)!);
      window.scrollTo(0, 0)
      setTimeout(() => setIsLoading(false), 1000);
    }).catch((err: any) => setTimeout(() => setIsLoading(false), 1000));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  return (
    <>
      {
        !isLoading ?
          <div>
            {/* Space */}
            <div className='my-5' />
            {
              product ?
                <div className='row'>
                  <div className='col-md-6 col-12'>
                    <div className='w-100'>
                      <img src={showImage} className='w-100' alt='banner.png' height={1024} width={1024} style={{ height: 'fit-content', objectFit: 'contain' }} />
                    </div>

                    {/* <div className='d-flex align-items-center'> */}
                    {/* <span id='slider-1-prev' style={{ width: 35, height: 35, borderRadius: '50%' }} className='d-flex align-items-center shadow px-2 mx-2 cursor-pointer bg-white'><FaArrowLeft className='primary-color' /></span> */}
                    <Swiper className='py-2' spaceBetween={50}
                      slidesPerView={3}
                      breakpoints={{
                        450: {
                          slidesPerView: 3,
                        },
                        768: {
                          slidesPerView: 4,
                        },
                        900: {
                          slidesPerView: 5,
                        },
                      }}
                    // navigation={{
                    //   nextEl: '#slider-1-next',
                    //   prevEl: '#slider-1-prev',
                    // }} 
                    // modules={[Navigation]}
                    >
                      {product?.img.map((item, i) => (
                        <SwiperSlide key={i}>
                          <div
                            style={{
                              width: 100,
                              height: 100,
                              border: "1px solid black",
                              cursor: "pointer",
                            }}
                          >
                            <img
                              onClick={() => setShowImage(item)}
                              style={{ width: "100%", height: "100%" }}
                              src={item}
                              alt="..."
                            />
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                    {/* <span id='slider-1-next' style={{ width: 35, height: 35, borderRadius: '50%' }} className='d-flex align-items-center shadow px-2 mx-2 cursor-pointer bg-white'><FaArrowRight className='primary-color' /></span> */}
                    {/* </div> */}
                  </div>

                  <div className='col-md-6 col-12 user-select-none' >
                    <div className='w-100 px-1 px-md-5' style={{ fontFamily: "DMSans-Medium" }}>
                      <label className='h1 fw-bold' style={{ color: "#9f86d9" }}>{product?.name}</label>
                      <div className='d-flex flex-wrap align-items-center'>
                        <p className='h4'>{product?.selling_price}</p>
                        <s className='h6 mx-2 text-gray'>{product?.price}</s>
                      </div>
                      <p>
                        {product?.description}
                      </p>
                      <div className='mt-5'>
                        <ReactWhatsapp className='btn btn-outline-success' element='button' number={Data?.number!} message={`I am interested in ${product?.name}`}>
                          <IoLogoWhatsapp className='mr-2 h3 mb-1' />
                          Send Message
                        </ReactWhatsapp>
                      </div>
                    </div>
                  </div>
                </div>
                : null
            }


            {/* Space */}
            <div className='py-5' />

            <div className='my-5'>
              <span className='category-name'>Related Product</span>
              <div className='row'>
                {
                  relatesProduct && relatesProduct?.map((item, i) => {
                    if (i > 3) return <React.Fragment key={i}></React.Fragment>
                    return (
                      <div className='col-md-6 col-lg-4 col-xxl-3 col-12 my-3' key={i}>
                        <div className='shadow'>
                          <div className='z-1'>
                            <img src={item?.img[0]} className='w-100 cursor-pointer' onClick={() => navigate(`/product/${item?.slug}`)} alt='banner.png' height={300} width={300} style={{ height: 300, objectFit: 'cover', objectPosition: 'top center' }} />
                          </div>
                          <div className='px-3 z-3' >
                            <div className='py-2'>
                              <h2 className='h4 cursor-pointer' onClick={() => navigate(`/product/${item?.slug}`)}>{item?.name}</h2>
                              <div className='d-flex justify-content-between flex-wrap align-items-center'>
                                <div className='d-flex flex-wrap align-items-center'>

                                  <p className='h4'>{item?.selling_price}</p>
                                  <s className='h6 mx-2 text-gray'>{item?.price}</s>
                                </div>
                                {/* <span className='h5'>{item?.price}</span> */}
                                <div className='d-flex align-items-center cursor-pointer'>
                                  <ReactWhatsapp element='span' number={Data?.number!} message={`I am interested in ${item?.name}`} className='btn'>
                                    <IoLogoWhatsapp className='mr-2 h3 mb-1' color='green' />
                                  </ReactWhatsapp>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
              </div>
            </div>
          </div>
          :
          <div className='my-5'>
            <div className='row'>
              <div className='col-lg-6 col-12'>
                <Skeleton className='w-100' style={{ height: 450 }} />
              </div>
              <div className='col-lg-6 col-12'>
                <Skeleton className='my-2 w-100' style={{ height: 35 }} />
                <Skeleton className='my-2 w-75' style={{ height: 35 }} />
                <Skeleton className='my-2 w-50' style={{ height: 35 }} />
              </div>
            </div>
          </div>}
    </>
  );
}
