import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'react-loading-skeleton/dist/skeleton.css'
import { SkeletonTheme } from 'react-loading-skeleton';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <SkeletonTheme baseColor="#d9d9d9" highlightColor="#f2f2f2">
      <App />
    </SkeletonTheme>
  </React.StrictMode>
);
