import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BlogsType } from '../types';
import PageNotFound from './PageNotFound';

export default function BlogsView() {

    const [blog, setBlog] = useState<BlogsType>();
    const [Data, setData] = useState<{ blogs: BlogsType[] }>();
    const navigate = useNavigate();
    const { slug } = useParams();

    useEffect(() => {

        fetch('/blogs.json').then(async (response) => {
            const data: { blogs: BlogsType[] } = await response.json();
            setData(data);
            setBlog(data?.blogs?.find(item => item?.slug === slug));
        })
            .catch((err: any) => console.log(err));
        window.scrollTo(0, 0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (blog) return (
        <div>
            <div className='my-2 mb-4'>
                <p className='mb-0 h1 text-break font-2-bold mb-3'>{blog?.title}</p>
                <p className='mb-0 text-break font-2-bold' style={{ fontSize: '20px' }}>{blog?.short_description}</p>
            </div>

            <div className='row mb-5'>

                <div className='col-lg-8 col-12'>

                        <div className='w-100 my-2 mb-4'>
                            <img className='w-100' loading='lazy' style={{ width: '80%' }} src={blog?.image} alt='blogs' />
                        </div>
                        <div className='ck-content text-break' dangerouslySetInnerHTML={{ __html: blog?.description ? blog?.description : "" }} />
                    <div className='px-1 px-lg-5'>
                    </div>
                </div>
                <div className='col-lg-4 col-12' style={{ height: 'fit-content', position: 'sticky', top: 85, overflowY: 'auto' }}>
                    <span className='category-name'>Must Read</span>
                    {Data?.blogs ? Data?.blogs.map((item, i) => {
                        if (i >= 5) return (<React.Fragment key={i}></React.Fragment>)
                        return (<div className='w-100' key={i}>
                            <div className='row h-100'>
                                <div className='col-4'>
                                    <div className='w-100 h-100 py-2'>
                                        <img loading='lazy' className='w-100 h-100' style={{ borderRadius: '4px', minHeight: '120px', maxWidth: '160px' }} src={item.image} alt='' />
                                    </div>
                                </div>
                                <div className='col-8 d-flex flex-column p-2'>
                                    <h3 className='cursor-pointer blog-titel  text-break' style={{ fontSize: '15px', textTransform: 'uppercase' }} onClick={() => navigate(`/post/${item?.slug}`)}>
                                        <strong>
                                            {item.title.slice(0, 100) +
                                                (item.title?.length > 100 ? "..." : "")}
                                        </strong>
                                    </h3>
                                    <p className='text-gray'>{item?.short_description.slice(0, 80) +
                                        (item?.short_description?.length > 80 ? "..." : "")}</p>
                                </div>
                            </div>
                        </div>)
                    }) : null}
                </div>
            </div>
        </div>
    );
    else return <PageNotFound />
}
