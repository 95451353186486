import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { IoLogoWhatsapp } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import ReactWhatsapp from 'react-whatsapp';
import { MdMenu, MdOutlineClose } from 'react-icons/md';
import { useGlobalStore } from '../store';
import { useEffect, useState } from 'react';

interface propsType {
    name: string,
    number: string,
    navItem: {
        label: string,
        slug: string
    }[],
}

function CustomNavbar({ name, number,navItem }: propsType) {
    const [isMobile, showSideBar, setShowSideBar] = useGlobalStore((state: any) => [state.isMobile, state.showSideBar, state.setShowSideBar]);
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('');
    const location = useLocation();

    useEffect(() => {
        const path = location.pathname.split('/')[1];
        setActiveTab(path);
    }, [location]);

    return (
        <Navbar expand="lg" className="bg-body-tertiary" fixed='top'>
            <Container>
                <div className='d-flex w-100 align-items-center'>
                    {isMobile ?
                        !showSideBar ? <button className='bg-transparent border-0' style={{ fontSize: '24px' }} type="button" onClick={() => setShowSideBar(true)}> <MdMenu /></button>
                            :
                            <button className='bg-transparent border-0' style={{ fontSize: '24px' }} type="button" onClick={() => setShowSideBar(false)}><MdOutlineClose /></button>
                        : null}
                    <span className='logo-text cursor-pointer h1' onClick={() => navigate('/')}>{name ? name : "kiddifi"}</span>
                    <div className='flex-grow-1' />

                    {!isMobile &&
                        <ul className='nav-tabs'>
                            <li className='tabs mx-2' onClick={() => navigate('/')}>Home</li>
                            {
                                navItem?.map((item,i)=><li key={i} className={`tabs mx-2 ${activeTab === item?.slug ? "text-decoration-underline" :""}`} onClick={() => navigate(`/${item?.slug}`)}>{item?.label}</li>)
                            }
                            {/* <li className={`tabs mx-2 ${activeTab === "posts" ? "text-decoration-underline" :""}`} onClick={() => navigate('/posts')}>Blogs</li> */}
                        </ul>}

                    <div className='flex-grow-1' />
                    {number ?
                        <ReactWhatsapp className='d-flex align-items-center cursor-pointer' element='div' number={number} message={`I am interested in your products.`}>
                            <IoLogoWhatsapp className='mx-2' color='green' size={35} />
                            <span className='fw-bold text-success'>{number}</span>
                        </ReactWhatsapp>
                        : null}
                </div>
            </Container>
        </Navbar>
    );
}

export default CustomNavbar;