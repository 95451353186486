import React, { useEffect, useState } from 'react';
import { jsonType } from '../types';

export default function ContactUs() {
  const [Data, setData] = useState<jsonType>();

  useEffect(() => {

    fetch('/db.json').then(async (response) => setData(await response.json()))
      .catch((err: any) => console.log(err));
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='w-100 d-flex flex-column align-items-center justify-content-center mx-md-5 mx-2 px-md-5 px-0'>
      <h1 className='font-1-bold color-main'>Contact Us</h1>
      <p className='h5 font-2-bold mt-2'>Have any questions? We'd love to hear from you! Here's how you can reach us:</p>
      <div className='font-2 row my-5 w-100'>
        <div className='col-md-6 col-12 d-flex align-items-center flex-column mb-5'>
            <h2 className='d-block'>Call Us</h2>
            <p>Phone: {Data?.number}</p>
        </div>
        <div className='col-md-6 col-12 d-flex align-items-center flex-column '>
            <h2>Email Us</h2>
            <p>Email: {Data?.email}</p>
        </div>
      </div>
    </div>
  );
}
