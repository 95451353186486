import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useGlobalStore } from '../store';

interface propsType{
    navItem: {
        label: string,
        slug: string
    }[]
}
export default function SideBar({navItem}:propsType) {
    const navigate = useNavigate();
    const setShowSideBar = useGlobalStore((state: any) => state.setShowSideBar);

    return (
        <div className='My-SideBar bg-white' style={{ minHeight: 'calc(100vh - 304px)', animation: 'opacity-up 0.5s ease-in' }}>
            <div className='p-2'>
                <div className='my-3'>
                    <ul className='d-flex flex-column'>
                        <li className='tabs mx-2' onClick={() => {
                            navigate('/');
                            setShowSideBar(false);
                        }}>Home</li>
                        {
                                navItem?.map((item,i)=><li key={i} className='tabs mx-2' onClick={() => {
                                    navigate(`/${item?.slug}`);
                                    setShowSideBar(false);
                                }}>{item?.label}</li>)
                        }
                        <li className='tabs mx-2' onClick={() => {
                            navigate('/posts');
                            setShowSideBar(false);
                        }}>Blogs</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}
