import React from 'react';
import { Container } from 'react-bootstrap';
import { IoLogoWhatsapp } from 'react-icons/io';
import { Link, useNavigate } from 'react-router-dom';
import ReactWhatsapp from 'react-whatsapp';
import { FaInstagramSquare, FaYoutube } from "react-icons/fa";

interface propsType {
    name: string,
    number: string,
    social_link: {
        youtube: string,
        whatsapp: string,
        instagram:string
    }
}

export default function Footer({ name, number, social_link }: propsType) {
    const navigate = useNavigate();
    return (
        <div className='w-100 py-5 bg-light'>
            <Container>
                <div className='d-flex justify-content-between align-items-center'>
                    <span className='logo-text h1'>{name ? name : "kiddifi"}</span>
                    <div className='d-flex justify-content-center h2'>
                        <Link  target="_blank" to={social_link?.youtube}><FaYoutube className='h2 mx-2 cursor-pointer' style={{ color: "#9f86d9" }} /></Link>
                        <Link  target="_blank" to={social_link?.instagram}><FaInstagramSquare className='h2 mx-2 cursor-pointer' style={{ color: "#9f86d9" }} /></Link>
                        {number ?
                            <ReactWhatsapp element='div' number={number} message={`I am interested in your products.`}>
                                <IoLogoWhatsapp className='h2 mx-2 cursor-pointer color-secondary' />
                            </ReactWhatsapp>
                            : null}
                    </div>
                </div>
                <hr />
                <div className='d-flex justify-content-between'>
                    <span>Copyright © 2024</span>
                    <div className='d-flex'>
                        <span className='mx-2 cursor-pointer' onClick={() => navigate('/about-us')}>About us</span>
                        <span className='mx-2 cursor-pointer' onClick={() => navigate('/contact-us')}>Contact us</span>
                    </div>
                </div>
            </Container>
        </div>
    );
}
