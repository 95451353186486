import React, { useEffect, useState } from 'react';
import { jsonType } from '../types';
import ReactWhatsapp from 'react-whatsapp';
import { IoLogoWhatsapp } from 'react-icons/io';
import { useNavigate, useParams } from 'react-router-dom';
import PageNotFound from './PageNotFound';
import Skeleton from 'react-loading-skeleton';

export default function ProductList() {

    const [Data, setData] = useState<jsonType>();
    const navigate = useNavigate();
    const { slug } = useParams();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetch('/db.json').then(async (response) => {
            setData(await response.json());
            setTimeout(() => setIsLoading(false), 1000);
        }).catch((err: any) => setTimeout(() => setIsLoading(false), 1000));
        window.scrollTo(0, 0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <>
            {
                !isLoading ? <>
                    {
                        Data?.category && Data?.category.map(item => item?.slug).includes(slug!) ? Data?.category.map((category, categoryIndex) => {
                            if (category?.slug !== slug) return <React.Fragment key={categoryIndex} />
                            return (
                                <div className='mb-5' key={categoryIndex}>
                                    <span className='w-100 text-center d-block category-name' style={{ fontSize: "clamp(40px, 4vw, 60px)" }}>{category.category_name}</span>
                                    <div className='row'>
                                        {
                                            category?.product && category?.product.map((item, i) => (
                                                <div className='col-md-6 col-lg-4 col-xxl-3 col-12 my-3' key={i}>
                                                    <div className='shadow '>
                                                        <div className='z-1'>
                                                            <img src={item?.img[0]} className='w-100 cursor-pointer' onClick={() => navigate(`/product/${item?.slug}`)} alt='banner.png' height={300} width={300} style={{ height: 300, objectFit: 'cover', objectPosition: 'top center' }} />
                                                        </div>
                                                        <div className='px-3 z-3' >
                                                            <div className='py-2'>
                                                                <h2 className='h4 cursor-pointer' onClick={() => navigate(`/product/${item?.slug}`)}>{item?.name}</h2>
                                                                <div className='d-flex justify-content-between flex-wrap align-items-center'>
                                                                    <div className='d-flex flex-wrap align-items-center'>

                                                                        <p className='h4'>{item?.selling_price}</p>
                                                                        <s className='h6 mx-2 text-gray'>{item?.price}</s>
                                                                    </div>
                                                                    {/* <span className='h5'>{item?.price}</span> */}
                                                                    <div className='d-flex align-items-center cursor-pointer'>
                                                                        <ReactWhatsapp element='span' number={Data?.number} message={`I am interested in ${item?.name}`} className='btn'>
                                                                            <IoLogoWhatsapp className='mr-2 h3 mb-1' color='green' />
                                                                        </ReactWhatsapp>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            )
                        })
                            : <PageNotFound />
                    }
                </>
                    :
                    <div className='my-5'>
                        <div className='row'>
                            {
                                Array.from({ length: 4 }).map((_, i) => (
                                    <div className='col-md-6 col-lg-4 col-xxl-3 col-12 my-3' key={i}>
                                        <div className='shadow'>
                                            <div className='z-1' style={{ height: 300, width: 300 }}>
                                                <Skeleton className='w-100 h-100' />
                                            </div>
                                            <div className='px-3 z-3' >
                                                <div className='py-2'>
                                                    <Skeleton className='w-100 h-100' style={{ height: 50, width: 100 }} />
                                                    <Skeleton className='w-75 h-100' style={{ height: 50, width: 100 }} />
                                                    <Skeleton className='w-50 h-100' style={{ height: 50, width: 100 }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
            }
        </>
    );
}
