import React from 'react';

export default function AboutUs() {
  return (
    <div className='w-100 d-flex flex-column align-items-center justify-content-center mx-md-5 mx-2 px-md-5 px-0'>
      <h1 className='font-1-bold color-main'>About Us</h1>

      <p className='h4 font-2-bold my-4'>Welcome to Kiddifi, your one-stop shop for the best in kids' cars, bikes, and clothes. We believe in providing quality products that spark joy and encourage creativity in children. Our store is a haven for young explorers looking to embark on new adventures.</p>

      <p className='h4 font-2-bold my-4'> our mission has always been to offer a wide range of products that cater to the unique needs and preferences of children. From stylish clothing to the latest in toy cars and bikes, we have something for every child.</p>
      <p className='h4 font-2-bold my-4'>Our dedicated team is committed to ensuring a safe and enjoyable shopping experience for both parents and kids. Come visit us and see what makes Kiddifi special!</p>
    </div>
  );
}
