import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './Layout';
import Home from './pages/Home';
import ProductView from './pages/ProductView';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import PageNotFound from './pages/PageNotFound';
import ProductList from './pages/ProductList';
import Blogs from './pages/Blogs';
import BlogsView from './pages/BlogsView';



function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="" element={<Layout />}>
          <Route path="" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/product/:slug" element={<ProductView />} />
          <Route path="/posts" element={<Blogs />} />
          <Route path="/post/:slug" element={<BlogsView />} />
          <Route path="/:slug" element={<ProductList />} />

          {/* 404 Page */}
          <Route path="*" element={<PageNotFound />} />

        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
