import React from 'react';

export default function PageNotFound() {
  return (
      <div className='d-flex justify-content-center flex-column align-items-center' style={{minHeight:'inherit'}}>
        <h1>Oops!</h1>
        <span className='h4'>404 Not Found</span>
      </div>
  );
}
