import React, { useEffect, useState } from 'react';
import { jsonType } from '../types';
import { IoLogoWhatsapp } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import ReactWhatsapp from 'react-whatsapp';
import { FaArrowRight } from 'react-icons/fa';
import { useGlobalStore } from '../store';
import Skeleton from 'react-loading-skeleton'

export default function Home() {

    const [Data, setData] = useState<jsonType>();
    const navigate = useNavigate();
    const isMobile = useGlobalStore((state: any) => state.isMobile);
    const [isLoading, setIsLoading] = useState(true);

    function getRandomProducts(arr: any[], numItems: number) {
        const newArr = [...arr];

        for (let i = newArr.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [newArr[i], newArr[j]] = [newArr[j], newArr[i]];
        }
        return newArr.slice(0, numItems);
    }

    useEffect(() => {
        fetch('/db.json').then(async (response) => {
            setData(await response.json());
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
        })
            .catch((err: any) => setTimeout(() => setIsLoading(false), 1000));
        window.scrollTo(0, 0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            {
                !isLoading ?
                    <>
                        {/* Main Banner */}
                        <div className='w-100'>
                            <img src={isMobile ? Data?.banner.mobile_img ? Data?.banner.mobile_img : Data?.banner?.img : Data?.banner?.img} className='w-100' alt='banner.png' height={1024} width={1024} style={{ height: 'fit-content', objectFit: 'contain' }} />
                        </div>

                        {/* Space */}
                        <div className='my-5' />

                        {/* product Listing */}

                        {
                            Data?.category && Data?.category.map((category, categoryIndex) => (
                                <div className='my-5' key={categoryIndex}>
                                    <div className='w-100 d-flex justify-content-between'>
                                        <span className='category-name'>{category.category_name}</span>
                                        <span className='h6 cursor-pointer text-primary' onClick={() => navigate(`/${category?.slug}`)}>View All <FaArrowRight /></span>
                                    </div>
                                    <div className='row'>
                                        {
                                            category?.product &&
                                            getRandomProducts(category?.product, category?.product.length < category?.limit ? category?.product.length : category?.limit).map((item, i) => {
                                                if (i >= category?.limit) return <React.Fragment key={i}></React.Fragment>
                                                return (
                                                    <div className='col-md-6 col-lg-4 col-xxl-3 col-12 my-3' key={i}>
                                                        <div className='shadow'>
                                                            <div className='z-1'>
                                                                <img src={item?.img[0]} className='w-100 cursor-pointer' alt='banner.png' height={300} width={300} style={{ height: 300, objectFit: 'cover', objectPosition: 'top center' }} onClick={() => navigate(`/product/${item?.slug}`)} />
                                                            </div>
                                                            <div className='px-3 z-3' >
                                                                <div className='py-2'>
                                                                    <h2 className='h4 cursor-pointer' onClick={() => navigate(`/product/${item?.slug}`)}>{item?.name}</h2>
                                                                    <div className='d-flex justify-content-between flex-wrap align-items-center'>
                                                                        <div className='d-flex flex-wrap align-items-center'>

                                                                            <p className='h4'>{item?.selling_price}</p>
                                                                            <s className='h6 mx-2 text-gray'>{item?.price}</s>
                                                                        </div>
                                                                        {/* <span className='h5'>{item?.price}</span> */}
                                                                        <div className='d-flex align-items-center cursor-pointer'>
                                                                            <ReactWhatsapp element='span' number={Data?.number} message={`I am interested in ${item?.name}`} className='btn'>
                                                                                <IoLogoWhatsapp className='mr-2 h3 mb-1' color='green' />
                                                                            </ReactWhatsapp>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                    </div>
                                </div>
                            ))
                        }
                    </>
                    :
                    <div className='container'>

                        <div className='w-100' style={{ height: 450 }}>
                            <Skeleton className='w-100 h-100' />
                        </div>

                        {/* Space */}
                        <div className='my-5' />

                        <div className='my-5'>
                            <div className='row'>
                                {
                                    Array.from({ length: 4 }).map((_, i) => (
                                        <div className='col-md-6 col-lg-4 col-xxl-3 col-12 my-3' key={i}>
                                            <div className='shadow'>
                                                <div className='z-1' style={{ height: 300, width: 300 }}>
                                                    <Skeleton className='w-100 h-100' />
                                                </div>
                                                <div className='px-3 z-3' >
                                                    <div className='py-2'>
                                                        <Skeleton className='w-100 h-100' style={{ height: 50, width: 100 }} />
                                                        <Skeleton className='w-75 h-100' style={{ height: 50, width: 100 }} />
                                                        <Skeleton className='w-50 h-100' style={{ height: 50, width: 100 }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
            }

        </>
    );
}
